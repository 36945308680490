<template>
    <div class="phone_number_Binding">
        <div class="phone_box_" :class="scss_style">
            <h1 class="h1_title__">可将结果永久保存到手机</h1>
            <div class="phone_number_box__">
                <span class="phone_text___">手机：</span>
                <input type="number" :class="input_error_class" v-model="phone_value" class="phone_input___ " placeholder="填写订单绑定号码，例：13566668888" @input="inputChange" name="" id="">
            </div>
            <button class="button__" :class="disabled_class" @click="phoneNumberSubmit" :disabled="disabled_fal">免费发送至以上手机<span v-if="disabled_fal">（{{num_key}}）</span></button>
            <div class="check_box__">
                <van-checkbox v-model="checked" icon-size=".25rem" checked-color="#4DBD54" shape="square">我已阅读并同意</van-checkbox><span class="check_text___" @click="privacyText">《服务条款和隐私政策》</span>
            </div>
        </div>
         <van-popup v-model="lookrules" style="width:90%;max-height:500px;" closeable>
            <div style="padding:.5rem;padding-top:20px;font-size:.26rem">
                <h1 class="center" style="text-align: center;margin: 0.5rem 0;font-size: 0.5rem;">用户隐私协议</h1>
                尊敬的用户，欢迎阅读本协议：<br/><br/>
               依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的 服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/><br/>

                用户在申请服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。<br/><br/>

                保护用户个人信息是 的一项基本原则， 运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可 不会向第三方（ 控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。<br/><br/>

                在用户发送信息的过程中和本网站收到信息后，本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。<br/><br/>

                此外，您已知悉并同意：在现行法律法规允许的范围内， 科技可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用 科技服务或产品的情况所认为您可能会感兴趣的信息。<br/><br/>

                本网站有权在必要时修改服务条例，本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。<br/><br/>

                如果您还有其他问题和建议，可以通过电子邮件673681507@qq.com或者电话17620368211联系我们。

                 保留对本协议的最终解释权。
                    <van-button class="redbtn" style="margin-top: .6rem;color: #C41713;background: #FEC995" @click="lookrules=false" round>关闭</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import axios from "../utils/request";
export default {
    data () {
        return{
            scss_style:'phone_box_',
            phone_value:'',
            input_error_class:'',
            disabled_fal:false,
            disabled_class:'',
            init_num:30,
            num_key:30,
            // num_key:5,
            checked:true,
            lookrules:false
        }
    },
    created () {
        this.init();
       
    },
    methods:{
        init(){
            switch (this.$route.path.split("/")[1]) {
                case 'home_marriage_test':
                    this.scss_style = 'phone_box_home_marriage_test'
                    break;
                case 'name_matching':
                     this.scss_style = 'phone_box_name_matching'
                    break;
                case 'fleeting_time_fortune':
                     this.scss_style = 'phone_box_fleeting_time_fortune'
                    break;
            
                default:
                    break;
            }
            if ( sessionStorage.getItem('num_key') === null ) {
                sessionStorage.setItem('num_key',this.init_num);
            } else if ( sessionStorage.getItem('num_key') < 1 ) {
                sessionStorage.setItem('num_key',this.init_num);
                this.num_key = this.init_num
            }else {
                if( sessionStorage.getItem('num_key') != this.init_num ){
                    this.disabled_fal = true;
                    this.disabled_class = 'disabled_style__';
                     this.num_key = sessionStorage.getItem('num_key')
                    let timer = setInterval( () => {
                        this.disabled_fal = true;
                        this.disabled_class = 'disabled_style__';
                        sessionStorage.setItem('num_key',sessionStorage.getItem('num_key')-1);
                        this.num_key = sessionStorage.getItem('num_key')
                        if ( sessionStorage.getItem('num_key') == 0 ) {
                            clearInterval(timer);
                            this.num_key = this.init_num;
                            sessionStorage.setItem('num_key',this.init_num);
                            this.disabled_fal = false;
                            this.disabled_class = ''
                        }
                    },1000)
                }
            };
            
        
        },
        inputChange(){
            this. input_error_class = '';
        },

        phoneNumberSubmit(){
           
            let sendTextMessage = ()=>{
                if ( this.phone_value === '' ) {
                    this.input_error_class = 'input_error_';
                    this.$toast({
                        message: '手机号不能为空',
                        position: 'top',
                    });
                }else if ( this.phone_value.length !== 11 ){
                    this.input_error_class = 'input_color_';
                    if( this.phone_value.length < 11 ) {
                        this.$toast({
                            message: `手机号缺少${ 11 - this.phone_value.length}位`,
                            position: 'top',
                        });
                    }else{
                    this.$toast({
                        message: `手机号超出${ this.phone_value.length - 11}位`,
                        position: 'top',
                    }); 
                    }
                }else{
                    let orderBindIphone_data_ = {
                        order_id: this.$route.params.id,
                        bind_iphone: this.phone_value,
                        rightnow:1
                    }
                    // axios.post(`/order/bindIphone`,orderBindIphone_data_).then( ( res ) => {

                    // });
                    // $orderBindIphone(orderBindIphone_data_).then( ( res ) => {
                    axios.post(`/order/bindIphone`,orderBindIphone_data_).then( ( res ) => {
                        let timer = setInterval( () => {
                            this.num_key--;
                            sessionStorage.setItem('num_key',this.num_key);
                            if ( this.num_key === 0 ) {
                                clearInterval(timer);
                                this.num_key = this.init_num;
                                sessionStorage.setItem('num_key',this.init_num);
                                this.disabled_fal = false;
                               
                                this.disabled_class = ''
                            }
                        },1000)
                        this.$toast.success("信息发送成功");
                        this.phone_value = '';
                        this.disabled_fal = true;
                        this.disabled_class = 'disabled_style__'
                    }).catch ((err)=>{
                        console.log(err)
                        if ( err.response.data.message === '两次绑定手机号码不一致') {
                            this.$toast.fail("两次绑定手机号码不一致");
                        }else if ( err.response.data.message === '手机号码不正确') {
                            this.$toast.fail("手机号码不正确");
                        }
                        else{
                            this.$toast.fail("信息发送失败");
                        }
                    })
                }
            }
            
            if( this.checked === true ){
                sendTextMessage()
            }else{
                this.$dialog.confirm({
                    title: '提示',
                    message: '请先同意隐私协议',
                    confirmButtonText:'同意'
                })
                .then(() => {
                    this.checked = true
                })
                .catch(() => {
                    // on cancel
                });
            }
        },
        privacyText(){
            this.lookrules = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .phone_number_Binding{
        .phone_box_{
            button{border: 0}
            width: 7.18rem;
            // height: 4.37rem;
            background: #FFFFFF;
            border: .01rem solid #BFBFBF;
            border-radius: .30rem;
            margin: auto;
            box-shadow: 0 0 8px -3px #000;
            .h1_title__{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #656565;
                background: rgba(211, 211, 211, .3);
                text-align: center;
                margin-top: .25rem;
            }
            .phone_number_box__{
                display: flex;
                align-items: center;
                width: 6.23rem;
                height: .88rem;
                border: .01rem solid #CCCCCC;
                border-radius: .10rem;
                margin: auto;
                margin-top: .15rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                .phone_text___{
                    white-space:nowrap;
                    margin-left: .25rem;
                }
                .phone_input___{
                    width: 80%;
                    outline: none;
                    border: none;
                }
                .input_color_{color: red;}
                .input_error_::-webkit-input-placeholder{color: red;}/*使用webkit内核的浏览器*/
                .input_error_::-moz-placeholder{color: red;}/*Firefox版本19+*/
                .input_error_:-ms-input-placeholder{color: red;}/*IE浏览器*/
            }
            .button__{
                display: block;
                width: 6.23rem;
                height: .88rem;
                line-height: .88rem;
                font-size: .36rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: rgba(201, 28, 24, .7);
                border-radius: .10rem;
                margin: auto;
                margin-top: .28rem;
            }
            .disabled_style__{
                background: #999;
            }
            .check_box__{
                display: flex;
                align-items: center;
                font-size: .26rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                margin-left: .48rem;
                margin-top: .28rem;
                margin-bottom: .6rem;
            }
        }
        .phone_box_name_matching{
            .h1_title__{
                background: rgba(255, 239, 246, .71);
                color: rgba(196, 57, 62, .71);
            }
            .button__{
                background: rgba(201, 28, 24, .85);
            }
        }
        .phone_box_fleeting_time_fortune{
            box-shadow: 0 0 8px -3px #5b584f;
        }
        .redbtn{
            display: block;
            width: 90%;
            margin: auto;
        }
    }
</style>